.popout-title {
  color: #0f3a57;
  margin: 0;
  font-family: Roboto Flex;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.popout-text {
  color: #0f3a574d;
  max-width: 365px;
  margin: 8px 0 0;
  font-family: Roboto Flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.popout-number {
  margin-right: 40px;
}

.popout-content {
  padding: 12px 40px 12px 24px;
}

@media only screen and (max-width: 480px) {
  .popout-title {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .popout-text {
    font-size: 14px !important;
    line-height: unset !important;
  }

  .philospohy {
    min-height: 1350px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .philospohy-content p {
    max-width: 370px;
  }

  .popout-1-div, .popout-2-div {
    flex-direction: column-reverse;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .popout-3-div {
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .popout-title {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .popout-text {
    font-size: 14px !important;
    line-height: unset !important;
  }

  .philospohy {
    min-height: 1350px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .meir-img {
    margin-top: -330px;
  }

  .philospohy-content p {
    width: 445px;
  }

  .popout-1-div {
    flex-direction: row-reverse;
    margin-right: 20px;
    position: absolute;
    bottom: 700px;
  }

  .popout-2-div {
    flex-direction: row-reverse;
    margin-right: 20px;
    position: absolute;
    bottom: 550px;
  }

  .popout-3-div {
    flex-direction: row;
    margin-right: 20px;
    position: absolute;
    bottom: 400px;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .philospohy {
    min-height: 1490px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .meir-img {
    margin-top: -330px;
  }

  .philospohy-content p {
    width: 445px;
  }

  .popout-1-div {
    margin-left: 20px;
    position: absolute;
    bottom: 710px;
    right: 26%;
  }

  .popout-2-div {
    flex-direction: row;
    margin-left: 20px;
    position: absolute;
    bottom: 490px;
    right: 5%;
  }

  .popout-3-div {
    flex-direction: row;
    margin-right: 20px;
    position: absolute;
    bottom: 100px;
    left: 20%;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .philospohy {
    min-height: 1400px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .meir-img {
    margin-top: -330px;
  }

  .philospohy-content p {
    width: 530px;
  }

  .popout-1-div {
    margin-left: 20px;
    position: absolute;
    bottom: 600px;
    right: 26%;
  }

  .popout-2-div {
    flex-direction: row;
    margin-left: 20px;
    position: absolute;
    bottom: 360px;
    right: 45%;
  }

  .popout-3-div {
    flex-direction: row;
    margin-right: 20px;
    position: absolute;
    bottom: 140px;
    left: 30%;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .philospohy {
    min-height: 1565px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .meir-img {
    margin-top: -330px;
  }

  .philospohy-content p {
    width: 690px;
  }

  .popout-1-div {
    margin-left: 20px;
    position: absolute;
    bottom: 600px;
    right: 42%;
  }

  .popout-2-div {
    flex-direction: row;
    margin-left: 20px;
    position: absolute;
    bottom: 360px;
    right: 50%;
  }

  .popout-3-div {
    flex-direction: row;
    margin-right: 20px;
    position: absolute;
    bottom: 200px;
    left: 48%;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1799px) {
  .philospohy {
    min-height: 1565px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .meir-img {
    margin-top: -330px;
  }

  .philospohy-content p {
    width: 690px;
  }

  .popout-1-div {
    margin-left: 20px;
    position: absolute;
    bottom: 600px;
    right: 48%;
  }

  .popout-2-div {
    flex-direction: row;
    margin-left: 20px;
    position: absolute;
    bottom: 360px;
    right: 50%;
  }

  .popout-3-div {
    flex-direction: row;
    margin-right: 20px;
    position: absolute;
    bottom: 200px;
    left: 48%;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2399px) {
  .philospohy {
    min-height: 1700px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .meir-img {
    margin-top: -330px;
  }

  .philospohy-content p {
    width: 690px;
  }

  .popout-1-div {
    position: absolute;
    bottom: 650px;
    right: 48%;
  }

  .popout-2-div {
    flex-direction: row;
    position: absolute;
    bottom: 410px;
    right: 58%;
  }

  .popout-3-div {
    flex-direction: row;
    position: absolute;
    bottom: 250px;
    left: 50%;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

@media only screen and (min-width: 2400px) {
  .philospohy {
    min-height: 1700px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .meir-img {
    margin-top: -330px;
  }

  .philospohy-content p {
    width: 690px;
  }

  .popout-1-div {
    position: absolute;
    bottom: 650px;
    right: 48%;
  }

  .popout-2-div {
    flex-direction: row;
    position: absolute;
    bottom: 410px;
    right: 58%;
  }

  .popout-3-div {
    flex-direction: row;
    position: absolute;
    bottom: 250px;
    left: 50%;
  }

  .plus-button-1 svg, .plus-button-2 svg, .plus-button-3 svg {
    width: 100px;
  }
}

/*# sourceMappingURL=index.dda43771.css.map */
