.popout-title {
    font-family: 'Roboto Flex';
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color:#0F3A57;
    margin: 0;
}

.popout-text {
    font-family: 'Roboto Flex';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color:#0F3A574D;
    margin: 0;
    max-width: 365px;
    margin-top: 8px;
}

.popout-number {
    margin-right: 40px;
}

.popout-content {
    padding: 12px 40px 12px 24px;
}


/* Mobile */
@media only screen and (max-width: 480px) {
    .popout-title {
        font-size: 20px !important;
        line-height: 24px !important;;
    }
    
    .popout-text {
        font-size: 14px !important;;
        line-height: unset !important;;
    }

    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1350px;
    }

    .philospohy-content p {
        max-width: 370px;
    }

    .popout-1-div, .popout-2-div  {
        margin-right:20px;
        margin-left:20px;
        flex-direction: column-reverse;
        align-items: center;
    }

    .popout-3-div {
        margin-right:20px;
        margin-left:20px;
        flex-direction: column;
        align-items: center;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
    .popout-title {
        font-size: 20px !important;
        line-height: 24px !important;;
    }
    
    .popout-text {
        font-size: 14px !important;;
        line-height: unset !important;;
    }

    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1350px;
    }
    .meir-img {
        margin-top:-330px;
    }
    .philospohy-content p {
        width: 445px;
    }

    .popout-1-div {
        position: absolute;
        bottom: 700px;
        margin-right:20px;
        flex-direction: row-reverse;
    }

    .popout-2-div {
        position: absolute;
        flex-direction: row;
        bottom: 550px;
        margin-right:20px;
        flex-direction: row-reverse;
    }

    .popout-3-div {
        position: absolute;
        flex-direction: row;
        bottom: 400px;
        margin-right:20px;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1490px;
    }
    .meir-img {
        margin-top:-330px;
    }
    .philospohy-content p {
        width: 445px;
    }

    .popout-1-div {
        position: absolute;
        bottom: 710px;
        right: 26%;
        margin-left:20px;
    }

    .popout-2-div {
        position: absolute;
        flex-direction: row;
        bottom: 490px;
        right: 5%;
        margin-left:20px;
    }

    .popout-3-div {
        position: absolute;
        flex-direction: row;
        bottom: 100px;
        left: 20%;
        margin-right:20px;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}

@media only screen and (min-width: 1025px) and (max-width:1199px) {

    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1400px;
    }
    .meir-img {
        margin-top:-330px;
    }
    .philospohy-content p {
        width: 530px;
    }

    .popout-1-div {
        position: absolute;
        bottom: 600px;
        right: 26%;
        margin-left:20px;
    }

    .popout-2-div {
        position: absolute;
        flex-direction: row;
        bottom: 360px;
        right: 45%;
        margin-left:20px;
    }

    .popout-3-div {
        position: absolute;
        flex-direction: row;
        bottom: 140px;
        left: 30%;
        margin-right:20px;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1399px) {
    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1565px;
    }
    .meir-img {
        margin-top:-330px;
    }
    .philospohy-content p {
        width: 690px;
    }

    .popout-1-div {
        position: absolute;
        bottom: 600px;
        right: 42%;
        margin-left:20px;
    }

    .popout-2-div {
        position: absolute;
        flex-direction: row;
        bottom: 360px;
        right: 50%;
        margin-left:20px;
    }

    .popout-3-div {
        position: absolute;
        flex-direction: row;
        bottom: 200px;
        left: 48%;
        margin-right:20px;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}

@media only screen and (min-width: 1400px) and (max-width:1799px) {
    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1565px;
    }
    .meir-img {
        margin-top:-330px;
    }
    .philospohy-content p {
        width: 690px;
    }

    .popout-1-div {
        position: absolute;
        bottom: 600px;
        right: 48%;
        margin-left:20px;
    }

    .popout-2-div {
        position: absolute;
        flex-direction: row;
        bottom: 360px;
        right: 50%;
        margin-left:20px;
    }

    .popout-3-div {
        position: absolute;
        flex-direction: row;
        bottom: 200px;
        left: 48%;
        margin-right:20px;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}

@media only screen and (min-width: 1800px) and (max-width:2399px) {
    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1700px;
    }
    .meir-img {
        margin-top:-330px;
    }
    .philospohy-content p {
        width: 690px;
    }

    .popout-1-div {
        position: absolute;
        bottom: 650px;
        right: 48%;
    }

    .popout-2-div {
        position: absolute;
        flex-direction: row;
        bottom: 410px;
        right: 58%;
    }

    .popout-3-div {
        position: absolute;
        flex-direction: row;
        bottom: 250px;
        left: 50%;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}

@media only screen and (min-width: 2400px) {
    .philospohy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 1700px;
    }
    .meir-img {
        margin-top:-330px;
    }
    .philospohy-content p {
        width: 690px;
    }

    .popout-1-div {
        position: absolute;
        bottom: 650px;
        right: 48%;
    }

    .popout-2-div {
        position: absolute;
        flex-direction: row;
        bottom: 410px;
        right: 58%;
    }

    .popout-3-div {
        position: absolute;
        flex-direction: row;
        bottom: 250px;
        left: 50%;
    }

    .plus-button-1 svg {
        width: 100px;
    }

    .plus-button-2 svg {
        width: 100px;
    }

    .plus-button-3 svg {
        width: 100px;
    }
}
